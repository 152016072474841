import { Alert, Button, Card, CardContent, Chip } from "@mui/material";
import { useParams } from "react-router-dom";

import ColonText from "@/components/ColonText";
import {
  formatTimestamp,
  isCurrentDate as isCurrentDateUtil,
} from "@/utils/dateUtils";
import { deliverystatusToMuiColor } from "@/utils/mappings";
import { verticalComponentMargin } from "@/utils/styleConstants";

const DeliveryCard = ({
  delivery,
  dispatchHandler,
  missHandler,
  isDeliveryWindowOpen,
}) => {
  const { date } = useParams();
  const { orderId, address, quantity, status, timeline } = delivery;
  const notes = timeline?.[timeline.length - 1]?.notes;
  const isCurrentDate = isCurrentDateUtil(date);

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Chip
          size="small"
          label={status}
          color={deliverystatusToMuiColor[status]}
          style={{ marginBottom: "8px" }}
        />
        <ColonText first="Order Id" second={orderId} />
        <ColonText
          first={"Customer Details"}
          second={`${address.fullName}, ${address.mobile}`}
        />
        <ColonText
          first={"Address"}
          second={`${address.building}, ${address.area}, ${address.district}, ${address.state}, ${address.country}, ${address.pincode}`}
        />
        <ColonText first={"Quantity"} second={quantity} />

        {status === "pending" && isCurrentDate && isDeliveryWindowOpen && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => dispatchHandler(orderId)}
            >
              Dispatch
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => missHandler(orderId)}
            >
              Miss
            </Button>
          </div>
        )}

        {status === "completed" && (
          <Alert severity="success">
            Delivery completed on{" "}
            {formatTimestamp(timeline[timeline.length - 1].timestamp)}
          </Alert>
        )}

        {status === "missed" && notes && (
          <Alert severity="error">Reason : {notes}</Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default DeliveryCard;
