import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import IconText from "@/components/IconText";
import { useData } from "@/contexts/DataContext";
import { formatTimestamp } from "@/utils/dateUtils";
import {
  expiryReasonMapping,
  orderStatusToMuiColor,
  settlementStatusToColor,
} from "@/utils/mappings";
import { verticalComponentMargin } from "@/utils/styleConstants";

const OrderCard = ({ order }) => {
  const data = useData();
  const navigate = useNavigate();

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Typography variant="h6">
            {order?.kitchen?.name || "Deleted Kitchen"}
          </Typography>
          <Chip
            size="small"
            label={order.finalStatus.toUpperCase()}
            color={orderStatusToMuiColor[order.finalStatus]}
          />
        </div>
        {order.finalStatus === "pending" && (
          <Alert severity="warning" style={{ marginBottom: "10px" }}>
            {`User need to revise the payment before ${formatTimestamp(
              order.payment.expiry,
            )} to activate the order`}
          </Alert>
        )}
        {order.finalStatus === "expired" && (
          <Alert severity="error" style={{ marginBottom: "10px" }}>
            {"Expiry reason: " +
              expiryReasonMapping[order.expiryDetails.reason]}
          </Alert>
        )}
        <IconText
          icon={<RestaurantMenuIcon />}
          text={`${data.cuisineLabels[order.cuisine]} ${
            data.mealTypeLabels[order.mealType]
          } ${data.mealTimeLabels[order.mealTime]} (${order.quantity})`}
        />
        <IconText
          icon={<ScheduleIcon />}
          text={data.periodLabels[order.period]}
        />
        <IconText icon={<CurrencyRupeeIcon />} text={`${order.price}`} />
        <IconText
          icon={<LocationOnIcon />}
          text={`${order.address.fullName} - ${order.address.district} ${order.pincode}`}
        />
        <Typography fontSize={12} color={"textSecondary"} textAlign={"center"}>
          Order placed on - {formatTimestamp(order.createdAt)}
        </Typography>
        {order.settlement && (
          <Typography
            fontSize={12}
            color={settlementStatusToColor[order.settlement.status]}
            textAlign={"center"}
          >
            Settlement status - {order.settlement.status}
          </Typography>
        )}
        {order.status === "active" && order.finalStatus === "expired" && (
          <Typography fontSize={12} color={"orange"} textAlign={"center"}>
            Your settlement will be processed soon
          </Typography>
        )}
        <Button
          fullWidth
          size="small"
          variant="contained"
          style={{ marginTop: "10px" }}
          onClick={() => navigate(`/orders/${order._id}`)}
          endIcon={<ArrowForwardIcon />}
        >
          View Order Details
        </Button>
      </CardContent>
    </Card>
  );
};

export default OrderCard;
