import { Card, CardContent } from "@mui/material";

import ColonText from "@/components/ColonText";
import { useData } from "@/contexts/DataContext";
import { formatTimestamp } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";

import { useOrderPageContext } from "../contexts/OrderPageContext";

const OrderSummary = () => {
  const data = useData();
  const { order } = useOrderPageContext();

  const {
    pincode,
    kitchen,
    cuisine,
    mealType,
    mealTime,
    quantity,
    period,
    startDate,
    endDate,
    price,
    createdAt,
    finalStatus,
    expiryDetails,
    timeline,
  } = order;

  const lastEventAt = formatTimestamp(timeline[timeline.length - 1]?.timestamp);

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <ColonText first={"Pincode"} second={pincode} />
        <ColonText first={"Kitchen"} second={kitchen?.name} />
        <ColonText first={"Cuisine"} second={data.cuisineLabels[cuisine]} />
        <ColonText first={"Meal Type"} second={data.mealTypeLabels[mealType]} />
        <ColonText first={"Meal Time"} second={data.mealTimeLabels[mealTime]} />
        <ColonText first={"Quantity"} second={quantity} />
        <ColonText first={"Price"} second={"₹" + price + " (incl. of taxes)"} />
        <ColonText first={"Period"} second={data.periodToDaysMap[period]} />
        <ColonText
          first={"Start Date"}
          second={new Date(startDate).toDateString()}
        />
        <ColonText
          first={"End Date"}
          second={new Date(endDate).toDateString()}
        />
        <ColonText
          first={"Order Placed on"}
          second={formatTimestamp(createdAt)}
        />
        {finalStatus === "expired" && (
          <ColonText
            first={"Expired At"}
            second={formatTimestamp(expiryDetails.expiredAt)}
          />
        )}
        {finalStatus === "cancelled" && (
          <ColonText first={"Cancelled At"} second={lastEventAt} />
        )}
        {finalStatus === "completed" && (
          <ColonText first={"Completed At"} second={lastEventAt} />
        )}
      </CardContent>
    </Card>
  );
};

export default OrderSummary;
