import { Typography } from "@mui/material";

import GreyCover from "@/components/GreyCover";

import DialerIcon from "./DialerIcon";
import NavigationIcon from "./NavigationIcon";

const DeliveryAddress = ({ address }) => {
  const { latitude, longitude, mobile } = address;
  return (
    <GreyCover>
      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
        Address
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography>{address.fullName}</Typography>
          <Typography>{address.mobile}</Typography>
        </div>
        <div>
          <NavigationIcon latitude={latitude} longitude={longitude} />
          <DialerIcon mobile={mobile} />
        </div>
      </div>
      <Typography variant="body2" color="text.secondary">
        {address.fullName}, {address.area}, {address.district}, {address.state},{" "}
        {address.country}, {address.pincode}
      </Typography>
    </GreyCover>
  );
};

export default DeliveryAddress;
