import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

import api from "@/api";
import useDebounce from "@/hooks/useDebounce";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

import AddressDetailsDrawer from "../../../components/AddressDetailsDrawer";
import CurrentLocation from "../../../components/CurrentLocation";
import LocationDisplay from "../../../components/LocationDisplay";
import LocationSearch from "../../../components/LocationSearch";
import Map from "../../../components/Map";
import SuggestionList from "../../../components/SuggestionList";

const NewAddress = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");
  const [isCurrentLocationGeocoding, setIsCurrentLocationGeocoding] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue.trim(), 300);

  useEffect(() => {
    const getPlacesAutocomplete = async () => {
      try {
        const {
          data: { suggestions },
        } = await api.maps.getPlacesAutocomplete({
          input: debouncedSearchValue,
        });
        if (suggestions) setSuggestions(suggestions);
      } catch (error) {
        handleError(error);
      }
    };

    if (debouncedSearchValue) {
      getPlacesAutocomplete();
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchValue]);

  const handleSuggestionSelect = async (suggestion) => {
    try {
      const {
        placePrediction: { placeId },
      } = suggestion;

      const {
        data: {
          formattedAddress,
          location: { latitude, longitude },
        },
      } = await api.maps.getPlaceDetails({ placeId });

      setLatitude(latitude);
      setLongitude(longitude);
      setLocation(formattedAddress);
      setSuggestions([]);
    } catch (error) {
      handleError(error);
    }
  };

  const reverseGeocodeLocation = async ({ latitude, longitude }) => {
    try {
      const {
        data: { results },
      } = await api.maps.reverseGeocode({ latitude, longitude });
      const { lat, lng } = results[0].geometry.location;
      const { formatted_address } = results[0];
      setLatitude(lat);
      setLongitude(lng);
      setLocation(formatted_address);
      setSuggestions([]);
      setSearchValue("");
    } catch (error) {
      handleError(error);
    }
  };

  const handleCurrentLocationGeocoding = async ({ latitude, longitude }) => {
    setIsCurrentLocationGeocoding(true);
    await reverseGeocodeLocation({ latitude, longitude });
    setIsCurrentLocationGeocoding(false);
  };

  const handleAddressAdd = async ({ building, area, landmark }) => {
    try {
      await api.kitchenRegistrations.updateAddress({
        location,
        latitude,
        longitude,
        building,
        area,
        landmark,
      });
      setIsOpen(false);
      navigate("/kitchen-registration/configuration");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <LocationSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <CurrentLocation onLocationCapture={handleCurrentLocationGeocoding} />
      {suggestions.length > 0 && (
        <SuggestionList
          suggestions={suggestions}
          onSuggestionSelect={handleSuggestionSelect}
        />
      )}
      {suggestions.length === 0 && location && (
        <LocationDisplay
          location={location}
          confirmLocationHandler={() => setIsOpen(true)}
          btnName={"Confirm Location & Add Address"}
        />
      )}
      {suggestions.length === 0 &&
        latitude &&
        longitude &&
        !isCurrentLocationGeocoding && (
          <>
            <Alert severity="info" style={{ margin: verticalComponentMargin }}>
              Drag the marker below to your exact location
            </Alert>
            <Map
              latitude={latitude}
              longitude={longitude}
              draggable
              handleDragEnd={reverseGeocodeLocation}
            />
          </>
        )}
      <AddressDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={"Add Address"}
        submitHandler={handleAddressAdd}
      />
    </>
  );
};

export default NewAddress;
