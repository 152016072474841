import { useNavigate } from "react-router-dom";

import BottomButton from "@/components/BottomButton";
import ReadOnlyFormComponents from "@/components/ReadOnlyFormComponents";

import LocationDisplay from "../../../components/LocationDisplay";
import Map from "../../../components/Map";

const ExistingAddress = ({ address }) => {
  const navigate = useNavigate();
  const { building, area, landmark, location, position } = address;
  const [longitude, latitude] = position.coordinates;
  const addressDetails = [
    { label: "Flat, House no., Building, Company, Apartment", value: building },
    { label: "Area, Street, Sector, Village", value: area },
    { label: "Landmark", value: landmark },
  ];

  return (
    <>
      <ReadOnlyFormComponents data={addressDetails} />
      <LocationDisplay location={location} viewLocationTitle />
      <Map latitude={latitude} longitude={longitude} />
      <BottomButton
        onClick={() => navigate("/kitchen-registration/configuration")}
      >
        Configuration
      </BottomButton>
    </>
  );
};

export default ExistingAddress;
