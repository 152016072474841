import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import BottomButton from "@/components/BottomButton";
import FormComponents from "@/components/FormComponents";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import handleError from "@/utils/handleError";
import { filterObj } from "@/utils/objectUtils";

import DeliveryRadiusMap from "./components/DeliveryRadiusMap";
import DeliveryRadiusSlider from "./components/DeliveryRadiusSlider";

const validationSchema = Yup.object().shape({
  cuisines: Yup.array().min(1, "Select at least one cuisine"),
  mealTypes: Yup.array().min(1, "Select at least one meal type"),
  mealTimes: Yup.array().min(1, "Select at least one meal time"),
  periods: Yup.array().min(1, "Select at least one period"),
  servingCapacityPerMealTime: Yup.number()
    .required("Capacity is required")
    .positive("Capacity must be a positive number")
    .max(1000, "Capacity should be less than 1000"),
  deliveryDays: Yup.array().min(1, "Select at least one delivery day"),
});

const ConfigurationPage = () => {
  const navigate = useNavigate();
  const data = useData();
  const [kitchenLatitude, setKitchenLatitude] = useState(null);
  const [kitchenLongitude, setKitchenLongitude] = useState(null);
  const [deliveryRadius, setDeliveryRadius] = useState(1000);

  const formBuilder = [
    {
      type: "multiSelect",
      label: "Cuisine",
      state: "cuisines",
      options: data.cuisinesData,
      value: "",
    },
    {
      type: "multiSelect",
      label: "Meal Type",
      state: "mealTypes",
      options: data.mealTypesData,
      value: "",
    },
    {
      type: "multiSelect",
      label: "Meal Time",
      state: "mealTimes",
      options: data.mealTimesData,
      value: "",
    },
    {
      type: "multiSelect",
      label: "period",
      state: "periods",
      options: data.periodsData,
      value: "",
    },
    {
      type: "input",
      inputType: "number",
      label: "Serving capacity per meal time",
      state: "servingCapacityPerMealTime",
      value: "",
    },
    {
      type: "multiSelect",
      label: "Delivery days",
      state: "deliveryDays",
      options: data.daysData,
      value: "",
    },
  ];

  const formik = useFormik({
    initialValues: {
      cuisines: [],
      mealTypes: [],
      mealTimes: [],
      periods: [],
      servingCapacityPerMealTime: "",
      deliveryDays: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.kitchenRegistrations.updateConfiguration({
          ...values,
          deliveryRadius,
        });
        navigate(`/kitchen-registration/pricing`);
      } catch (error) {
        handleError(error);
      }
    },
  });

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const {
          data: { kitchenRegistration },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();

        if (!kitchenRegistration.address) {
          toast.error("Please add kitchen address");
          return navigate("/kitchen-registration/address", { replace: true });
        }
        setKitchenLatitude(kitchenRegistration.address.latitude);
        setKitchenLongitude(kitchenRegistration.address.longitude);

        const { configuration } = kitchenRegistration;
        if (configuration) {
          formik.setValues(
            filterObj(
              configuration,
              "cuisines",
              "mealTypes",
              "mealTimes",
              "periods",
              "servingCapacityPerMealTime",
              "deliveryDays",
            ),
          );
          setDeliveryRadius(configuration.deliveryRadius);
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchConfiguration();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Configuration</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents data={formBuilder} formik={formik} />
          <DeliveryRadiusSlider
            radius={deliveryRadius}
            setRadius={setDeliveryRadius}
          />
          {kitchenLatitude && kitchenLongitude && (
            <DeliveryRadiusMap
              latitude={kitchenLatitude}
              longitude={kitchenLongitude}
              radius={deliveryRadius}
            />
          )}
          <BottomButton>Pricing</BottomButton>
        </form>
      </Container>
    </>
  );
};

export default ConfigurationPage;
