import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { Card, CardContent } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

import IconText from "./IconText";

const AddressSummary = ({ address }) => {
  const {
    fullName,
    mobile,
    building,
    area,
    landmark,
    district,
    state,
    country,
    pincode,
  } = address;
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <IconText icon={<PersonIcon />} text={fullName} />
        <IconText icon={<CallIcon />} text={mobile} />
        <IconText
          icon={<HomeIcon />}
          text={`${building}, ${area}, ${district}, ${
            landmark && landmark + ", "
          } ${state}, ${pincode}, ${country}.`}
        />
      </CardContent>
    </Card>
  );
};

export default AddressSummary;
